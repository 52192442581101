<template>
    <!-- <BT-Blade
        hideBladeHeader> -->
        <v-container>
            <v-row>
                <v-col cols="12" sm="6">
                    <v-img 
                        max-height="300"
                        max-width="300"
                        class="mx-auto"
                        :src="imageURL('xero.png')">
                        <template v-slot:placeholder>
                            <v-icon size="100" color="primary">mdi-account</v-icon>
                        </template>
                    </v-img>
                </v-col>
                <v-col cols="12" sm="6" class="d-flex">
                    <BT-Btn-Row class="align-self-center justify-self-center">
                        <template v-slot:center>
                            <BT-Btn
                                icon="mdi-account"
                                label="Customers"
                                @click="tab = 1" />
                            <BT-Btn
                                icon="mdi-account"
                                label="Products"
                                @click="tab = 2" />
                            <BT-Btn
                                icon="mdi-account"
                                label="Auto Lines"
                                @click="tab = 3" />
                            <BT-Btn
                                icon="mdi-account"
                                label="Mapping"
                                @click="tab = 4" />
                        </template>
                    </BT-Btn-Row>
                </v-col>
            </v-row>
            <v-card>
                <v-tabs v-model="tab">
                    <v-tab>Home</v-tab>
                    <v-tab>Customers</v-tab>
                    <v-tab>Products</v-tab>
                    <v-tab>Auto Lines</v-tab>
                    <v-tab>Product Mappings</v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-tab-item>
                            <BT-Btn-Row>
                                <v-col>
                                    <v-card>
                                        <v-card-title>Customers</v-card-title>
                                        <v-card-text>
                                            5 Synced
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </BT-Btn-Row>
                            <!-- <v-container>
                                <v-row>
                                    <v-col cols="12" sm="6">

                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-list>

                            </v-list>
                            <v-card-text>
                                <v-icon class="mx-auto my-5 pa-4 justify-self-center" size="150">mdi-sync</v-icon>
                            </v-card-text> -->
                            
                        
                    </v-tab-item>
                    <v-tab-item>
                        <BT-Table-Sync
                            hideBackButton
                            hideBladeHeader
                            navigation="customer-syncing"
                            party="xero"
                            :onPullNewItems="newCustomers"
                            :searchProps="['localItem.buyer.companyName','externalItem.Name']"
                            title="Customers" />
                    </v-tab-item>
                    <v-tab-item>
                        <BT-Table-Sync 
                            title="Products"
                            navigation="product-syncing"
                            party="xero" />
                    </v-tab-item>
                    <v-tab-item>
                        <BT-Table-Sync 
                            title="Automatic Lines"
                            navigation="automatic-line-item-syncing"
                            party="xero" />
                    </v-tab-item>
                    <v-tab-item>
                        <v-expansion-panels>
                            <BT-Expansion-Panel-List label="Descriptions">
                                <template v-slot:items>
                                    <BT-Mappings
                                        navigation="order-invoice-mappings"
                                        externalParty="xero"
                                        externalProperty="Description"
                                        isEditing />
                                </template>
                            </BT-Expansion-Panel-List>
                            <BT-Expansion-Panel-List label="Tax Types">
                                <template v-slot:items>
                                    <BT-Mappings
                                        navigation="order-invoice-mappings"
                                        externalParty="xero"
                                        externalProperty="TaxType"
                                        isEditing />
                                </template>
                            </BT-Expansion-Panel-List>
                            <BT-Expansion-Panel-List label="Item Codes">
                                <template v-slot:items>
                                    <BT-Mappings
                                        navigation="order-invoice-mappings"
                                        externalParty="xero"
                                        externalProperty="ItemCode"
                                        isEditing />
                                </template>
                            </BT-Expansion-Panel-List>
                            <BT-Expansion-Panel-List label="Account Codes">
                                <template v-slot:items>
                                    <BT-Mappings
                                        navigation="order-invoice-mappings"
                                        externalParty="xero"
                                        externalProperty="AccountCode"
                                        isEditing />
                                </template>
                            </BT-Expansion-Panel-List>
                            <BT-Expansion-Panel-List label="Tracking Category Name One">
                                <template v-slot:items>
                                    <BT-Mappings
                                        navigation="order-invoice-mappings"
                                        externalParty="xero"
                                        externalProperty="TrackingCategoryNameOne"
                                        isEditing />
                                </template>
                            </BT-Expansion-Panel-List>
                            <BT-Expansion-Panel-List label="Tracking Category Option One">
                                <template v-slot:items>
                                    <BT-Mappings
                                        navigation="order-invoice-mappings"
                                        externalParty="xero"
                                        externalProperty="TrackingCategoryOptionOne"
                                        isEditing />
                                </template>
                            </BT-Expansion-Panel-List>
                            <BT-Expansion-Panel-List label="Tracking Category Name Two">
                                <template v-slot:items>
                                    <BT-Mappings
                                        navigation="order-invoice-mappings"
                                        externalParty="xero"
                                        externalProperty="TrackingCategoryNameTwo"
                                        isEditing />
                                </template>
                            </BT-Expansion-Panel-List>
                            <BT-Expansion-Panel-List label="Tracking Category Option Two">
                                <template v-slot:items>
                                    <BT-Mappings
                                        navigation="order-invoice-mappings"
                                        externalParty="xero"
                                        externalProperty="TrackingCategoryOptionTwo"
                                        isEditing />
                                </template>
                            </BT-Expansion-Panel-List>
                        </v-expansion-panels>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-container>
    <!-- </BT-Blade> -->
</template>

<script>
import { getStreetNumber } from '~helpers';
import { getStreetName } from '~helpers';

export default {
    name: 'Xero-Integration-Panel',
    components: {
        BTMappings: () => import('~components/BT-Mappings.vue'),
        BTTableSync: () => import('~components/BT-Table-Sync.vue')
    },    
    data: function() {
        return {
            tab: 0
        }
    },
    methods: {
        createNewItem() {
            return {
                id: null,
                externalPartyID: 'xero',
                externalProperty: null,
                externalValue: null,
                itemType: null,
                itemID: null
            }
        },
        newCustomers(extList) {
            var newCustList = [];

            for (let i = 0; i < extList.length; i++) {
                const extItem = extList[i].externalItem;
                
                var nItem = {
                    companyName: extItem.Name,
                    addressLineOne: null,
                    streetNumber: null,
                    streetName: null,
                    suburb: null,
                    state: null,
                    postcode: null,
                    email: extItem.EmailAddress ? extItem.EmailAddress : null,
                    phoneNumber: null,
                    xeroID: extList[i].externalItemID
                };

                var address = null;

                if (extItem.Addresses && extItem.Addresses.length > 0) {
                    var streetAddress = extItem.Addresses.find(x => x.AddressType === 'STREET');
                    var poAddress = extItem.Addresses.find(x => x.AddressType === 'POBOX');
                    
                    if (poAddress && !streetAddress?.City && !streetAddress?.PostalCode && !streetAddress?.Region) {
                        address = poAddress;
                    }
                    else if (streetAddress) {
                        address = streetAddress;
                    }

                    if (!address && extItem.Addresses.length > 0) {
                        address = extItem.Addresses[0];
                    }
                }

                if (address != null) {
                    if (address.EmailAddress) {
                        nItem.email = address.EmailAddress;
                    }
                    if (address.Region) {
                        nItem.state = address.Region;
                    }
                    if (address.PostalCode) {
                        nItem.postcode = address.PostalCode;
                    }
                    if (address.City) {
                        nItem.suburb = address.City;
                    }
                    if (address.AddressLine2) {
                        nItem.streetNumber = getStreetNumber(address.AddressLine2);
                        nItem.streetName = getStreetName(address.AddressLine2);
                        
                        if (address.AddressLine1) {
                            nItem.addressLineOne = address.AddressLine1;
                        }
                    }
                    else if (address.AddressLine1) {
                        nItem.streetNumber = getStreetNumber(address.AddressLine1);
                        nItem.streetName = getStreetName(address.AddressLine1);
                    }
                }

                if (extItem.Phones && extItem.Phones.length > 0) {
                    var phoneNumber = extItem.Phones.find(x => x.PhoneType === 'DEFAULT' && x.PhoneNumber);

                    if (!phoneNumber) {
                        phoneNumber = extItem.Phones.find(x => x.PhoneType === 'MOBILE' && x.PhoneNumber);
                    }

                    if (!phoneNumber) {
                        phoneNumber = extItem.Phones[0];
                    }
                    
                    var a = phoneNumber.PhoneAreaCode ? phoneNumber.PhoneAreaCode : '';
                    var b = phoneNumber.PhoneNumber ? phoneNumber.PhoneNumber : '';
                    nItem.phoneNumber = a + b;
                }

                newCustList.push(nItem);
            }

            this.$router.push({ name: 'new-customers', params: { customers: newCustList } });
        }
    }
}
</script>